
/**
 * @name: equipment
 * @author: Gzm
 * @date: 2023-07-27 16:16
 * @description：销售管理-设备管理
 * @update: 2023-07-27 16:16
 */
import {Vue, Component} from "vue-property-decorator"
import {IOrderManage, IOrderManageParam} from "@/apis/site/orderManage/types"
import {mallSiteQueryByListApi} from "@/apis/site/list"
import {ICrudOption} from "@/types/m-ui-crud"
import {
  printersActivationApi,
  printersCreateApi,
  printersDetailApi, printersModifyApi,
  printersQueryApi,
  printersRemoveApi
} from "@/apis/sale/availableInventory";

@Component({})
export default class OrderManagePage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: IOrderManage[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IOrderManageParam = {
    page: 1,
    limit: 10
  }
  // 表单数据
  modelForm: Partial<IOrderManage> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    dialogWidth: '600px',
    addTitle: '新增',
    editTitle: '编辑',
    column: [
      {
        label: "设备ID",
        prop: "id",
        align: "left",
        editHide: true,
        addHide: true
      },
      {
        label: "设备编号",
        prop: "deviceSn",
        align: "center",
        overHidden: true,
        maxlength: 20,
        span: 24,
        rules: [
          {required: true, message: '请输入设备编号', trigger: 'blur'}
        ],
        search: true
      },
      {
        label: "设备名称",
        prop: "deviceName",
        align: "center",
        overHidden: true,
        maxlength: 20,
        span: 24,
        rules: [
          {required: true, message: '请输入设备名称', trigger: 'blur'}
        ],
        search: true
      },
      {
        label: "绑定站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
        addHide: true,
        editHide: true
      },
      {
        label: "绑定站点",
        prop: "siteId",
        span: 24,
        search: true,
        type: "select",
        dicData: [],
        hide: true,
        rules: [
          {required: true, message: '请选择站点', trigger: 'blur'},
        ]
      },
    ]
  }

  openAdd() {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    this.getSiteSelect()
  }

  activate(){
    printersActivationApi().then(e => {
      if (e){
        this.$message.success('操作成功')
      }
    })
  }

  openEdit(row: any, index: number) {
    printersDetailApi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
      this.getSiteSelect()
    })
  }

  getList() {
    this.tableLoading = true
    printersQueryApi(this.queryForm).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  rowSave(form: any, done: Function, loading: Function) {
    printersCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit(form: any, done: Function, loading: Function) {
    printersModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }


  resetPwd(row: any, index: number) {
    this.$confirm('是否确认删除？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      printersRemoveApi(row.id).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getList()
        }
      })
    }).catch(() => {
    })
  }

  getSiteSelect() {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  created() {
    this.getList()
    this.getSiteSelect()
  }
}
